import React, { useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

const Mie = () => {
  const [scrolled, setScrolled] = useState(false);
  const Mie =require("../../Images/Mie.jpg")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'Established formula management system',
    'Reliable lot and tracking solution',
    'Effective batch sizing and scaling',
    'Intelligent Bill of Materials formula calculations',
    'Trusted Globally Harmonized System compliance capability',
    'Inventory management and reporting',
    'Support for CGMP and other compliance regulations',
    'Quality document management portal'
  ];
  
  

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Manufacturing Industry ERP</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Mie} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Mie;
