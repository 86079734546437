import React, { useState } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import "./style.css"

export default function About() {
    const Quotation =require('../../Images/Quote.jpg')
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        product: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log(formData);
    };
  return (
    <>
    <div>
    <Header />
    </div>
    <div>
    <div className="ticker-container">
            <div className="ticker-wrap">
                <div className="ticker-item">Vision: Helping businesses grow with the latest software technologies.</div>
                <div className="ticker-item">Mission: GSK TECH delivers reliable solutions to improve IT efficiency and business profitability.</div>
            </div>
        </div> 
    </div>
    <div className="quotation-container">
    <div className="image-container">
        <img src={Quotation} alt="Get Quotation" className="quotation-image" />
      </div>
      <div className="form-container">
            <h2 className="heading">Get Quotation!</h2>
            <p className="subheading">Get the quotation for our products within 24 hours.</p>
            <form onSubmit={handleSubmit} className="quotation-form">
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="product"
                        placeholder="Product"
                        value={formData.product}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </div>
        </div>
    <div>
        <Footer/>
    </div>
    </>
    
  )
}
