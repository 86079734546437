import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import About from '../Component/About';
import Client from '../Component/Client';
import Solutions from '../Component/Solutions';
import Services from '../Component/Services';
import BeautyPro from '../Component/BeautyPro';
import Inventory from '../Component/Inventory';
import Ems from '../Component/Ems';
import Crm from '../Component/Crm';
import Retail from '../Component/Retail';
import Sms from '../Component/Sms';
import Mie from '../Component/Mie';
import Business from '../Component/Business';
import ContactUs from '../Pages/ContactUs';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/our-clients" element={<Client/>} />
        <Route path="/solutions" element={<Solutions/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/pos" element={<BeautyPro/>} />
        <Route path="/inventory-management" element={<Inventory/>} />
        <Route path="/employee-management-system" element={<Ems/>} />
        <Route path="/customer-relationship-managementcrm" element={<Crm/>} />
        <Route path="/retail-pos" element={<Retail/>} />
        <Route path="/student-management-system" element={<Sms/>} />
        <Route path="/manufacturing-industry-erp" element={<Mie/>} />
        <Route path="/manufacturing-industry-erp" element={<Mie/>} />
        <Route path="/small-and-medium-business-solutions" element={<Business/>} />
        <Route path="/contact-us" element={<ContactUs/>} />

      </Routes>
    </Router>
  );
}

export default App;
