import React, { useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

const Sms = () => {
  const [scrolled, setScrolled] = useState(false);
  const Sms =require("../../Images/Sms.jpg")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'Student Behaviour Tracking and Analytics',
    'Campus Recruitment',
    'Admission Management',
    'Account Management',
    'Laboratory Management',
    'Profile Management',
    'Attendance Management',
    'Examination Management',
    'Management Reports'
  ];
  

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Student Management Systems</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Sms} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Sms;
