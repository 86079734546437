import React, { useState, useEffect } from 'react';
import './style.css'; // CSS file for styling
import Header from '../Header';
import Footer from '../Footer';

const Inventory = () => {
  const [scrolled, setScrolled] = useState(false);
  const Inv =require("../../Images/Inventory.jpg")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'Monitor Inventory Levels',
    'Automatic Replenishment',
    'Reporting: Supplied products, Inventory summary, transaction reports, order history, and Inventory totals',
    'Quotations',
    'Invoicing'
  ];

  
    
  

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Inventory Management</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Inv} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Inventory;
