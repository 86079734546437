import React from 'react';
import './style.css'; // Import your custom styles

export default function Features() {
  const Beauty = require('../../Images/Beauty.jpg');
  const Manu = require('../../Images/Manu.jpg');
  const Punch = require('../../Images/Punch.jpg');
  const Smb = require('../../Images/Smb.jpg');
  const Crm = require('../../Images/Crm.jpg');
  const Inv = require('../../Images/Inv.jpg');
  
  return (
    <div className="container my-5">
      {/* Row 1 */}
      <div className="row justify-content-center mb-5">
        <div className="col-md-4">
          <div className="text-center">
            <img src={Beauty} alt="Beauty" className="img-fluid img-custom" />
            <h5 className="Fea-hea mt-4">Beauty Industry POS</h5>
            <p className="Fea-content">Cashier Customer Appointment Products, Services, Package Sales Option Package Upgrade and Modified Promo Customer Tracking Receipt Hold/Resume FOC Service Customer Call Update /History Marketing Efficiency Report Product Ordering, Receiving Consumption Product Detail Staff Commission (Automated and Manual) Multi Location</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="text-center">
            <img src={Manu} alt="Manu" className="img-fluid img-custom" />
            <h5 className="Fea-hea mt-4">Manufacturing Industry ERP</h5>
            <p className="Fea-content">The Manufacturing module provides everything you need to plan, control and follow up on your production. Established formula management system Reliable lot and tracking solution Effective batch sizing and scaling Intelligent Bill of Materials formula calculations Trusted Globally Harmonized System compliance capability</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="text-center">
            <img src={Punch} alt="Punch" className="img-fluid img-custom" />
            <h5 className="Fea-hea mt-4">Employee Management System</h5>
            <p className="Fea-content">User friendly system Records Employee Information Security rights define the access people have to the information Records the current number of employees according to division / department with the automatic updating of figures when people are employed or leave Keeps</p>
          </div>
        </div>
      </div>

      {/* Row 2 */}
      <div className="row justify-content-center mb-5">
        <div className="col-md-4">
          <div className="text-center">
            <img src={Smb} alt="Smb" className="img-fluid img-custom" />
            <h5 className="Fea-hea mt-4">Small and Medium Business Solutions</h5>
            <p className="Fea-content">Multi User, Multi Department and Multi Company Employee wise Sale Product wise Sale Group Sale Quotation and Invoicing Purchase order Good Receiving Expenses Project wise cost plan clients and Supplier info User wise security Control GST & CPF Automation Report</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="text-center">
            <img src={Crm} alt="Crm" className="img-fluid img-custom" />
            <h5 className="Fea-hea mt-4">Customer Relationship Management (CRM)</h5>
            <p className="Fea-content">Contact Management Flexible Security & Access Permissions Billing & Invoicing Functionality Data Reporting Mobile CRM including a Mobile App and Alerts Sales Performance Management Manage each customer case individually and efficiently to improve customer satisfaction levels.</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="text-center">
            <img src={Inv} alt="Inv" className="img-fluid img-custom" />
            <h5 className="Fea-hea mt-4">Retail POS</h5>
            <p className="Fea-content">Sales Reporting & Analytics: Transparency Into Data. Customer Management: Attract Valuable Customers. Employee Management: The Face of Your Business. Inventory Management: Eliminate Manual Inventory. POS: This system reduces the potential for human error by automating such checkout tasks as item</p>
          </div>
        </div>
      </div>
    </div>
  );
}
