import React, { useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

const Ems = () => {
  const [scrolled, setScrolled] = useState(false);
  const Ems =require("../../Images/Ems.jpg")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'User friendly system',
    'Records Employee Information',
    'Security rights define the access people have to the information',
    'Records the current number of employees according to division/department with automatic updating of figures when people are employed or leave',
    'Keeps records of inactive employees',
    'Search facility for easy finding of staff',
    'Share Memo and Share Training Materials (PDF, Photos, Videos, etc.)',
    'Group Chatting',
    'Meeting alert',
    'Employer can give training from the chat group by category',
    'Employee Registration and Resignation update',
    'E-Leave and Shift allocation',
    'Employee Roster and E-Leave Availability',
    'Employee appraisal',
    'Employee Claims',
    'Employee Fingerprint and Face Recognition Device Automation'
  ];
  
    

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Employee Management System</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Ems} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Ems;
