import React, { useState, useEffect } from 'react';
import './style.css'; // CSS file for styling
import Header from '../Header';
import Footer from '../Footer';

const Product = () => {
  const [scrolled, setScrolled] = useState(false);
  const Pos =require("../../Images/Pos.png")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'Cashier',
    'Customer Appointment',
    'Products, Services, Package Sales Option',
    'Package Upgrade and Modified',
    'Promo Customer Tracking',
    'Receipt Hold/Resume',
    'FOC Service',
    'Customer Call Update /History',
    'Marketing Efficiency Report', // Corrected spelling: "Effectioncy" to "Efficiency"
    'Product Ordering, Receiving', // Corrected spelling: "Receving" to "Receiving"
    'Consumption Product Detail',
    'Staff Commission (Automated and Manual)',
    'Multi Location / Multi Brand',
    'Staff Attendance, Shift'
  ];
  

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Beauty Industry Pos</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Pos} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Product;
