import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import './style.css';

export default function Services() {
    const Ser = require('../../Images/Ser.jpg');
  return (

    <>
      <Header />
      <div className="services-container">
        <div className="content">
          <h2 className="ser-heading">Services</h2>
          <div className="company-intro">
            <p>GSK Technology is a leading software service solution company and a Microsoft Gold Partner.</p>
          </div>

          <div className="service-categories">
            <h5>We Specialize In:</h5>
            <ul>
              <li>Office 365 Implementation, Deployment, Migration, and Setup</li>
              <li>Windows Server Virtualization</li>
              <li>MS-SQL Server Setup and Database Migration</li>
              <li>Network Security</li>
              <li>Infrastructure Management</li>
              <li>Web Application Development</li>
              <li>Software Development</li>
              <li>Hosting and Management</li>
              <li>System Integration</li>
              <li>Maintenance and Support</li>
            </ul>
          </div>

          <div className="application-development">
            <h5>Application Development, System Integration, and Management</h5>
            <p>GSK Technology offers a wide spectrum of services to cater to MNCs, SMEs, and Non-Profit Organizations:</p>
            <ul>
              <li>Project Management</li>
              <li>Business and Infrastructure Consultancy</li>
              <li>System Analysis</li>
              <li>Customized Solution Development</li>
              <li>Application Software Designing</li>
              <li>Application Software Development</li>
              <li>Testing and User Acceptance Trials</li>
              <li>System Maintenance</li>
              <li>System Upgrade</li>
              <li>System Analysis and Workflow Improvement</li>
              <li>Security Audit with Software Audit</li>
              <li>eMarketing Campaigns</li>
              <li>Staff Training to Cultivate Pervasive Use of IT</li>
            </ul>
          </div>

        </div>

        <div className="image">
          <img src={Ser} alt="Our Services" />
          
          <div className="experience">
            <p>With a decade of software solution experience behind us, we have the capabilities to support virtually every type of application, from legacy database systems to web-based, customized software and off-the-shelf IT solutions.</p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
