import React, { useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

const Retail = () => {
  const [scrolled, setScrolled] = useState(false);
  const Pos =require("../../Images/Pos.png")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'Sales Reporting & Analytics: Transparency Into Data',
    'Customer Management: Attract Valuable Customers',
    'Employee Management: The Face of Your Business',
    'Inventory Management: Eliminate Manual Inventory',
    'POS: This system reduces the potential for human error by automating such checkout tasks as item look-up and price calculation'
  ];
  

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Retail POS</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Pos} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Retail;
