import React from 'react';
import "./style.css"
import Header from '../Header';
import Footer from '../Footer';

export default function Client() {
    const images = [
        require('../../Images/Client1.jpg'),
        require('../../Images/Client2.jpg'),
        require('../../Images/Client3.jpg'),
        require('../../Images/Client4.jpg'),
        require('../../Images/Client5.jpg'),
        require('../../Images/Client6.jpg'),
        require('../../Images/Client7.jpg'),
        // require('../../Images/Client8.jpg'),
        require('../../Images/Client9.jpg'),
        require('../../Images/Client10.jpg'),
       
    
    ];
    
  return (
    <div>
        <Header/>
        <h2 className="client-heading">Our Clients</h2>

         <div className="gallery-container">
            {images.map((src, index) => (
                <div key={index} className="gallery-item">
                    <img src={src} alt={`Client ${index + 1}`} className="gallery-image" />
                </div>
            ))}
        </div>
        <Footer/>
    </div>
  )
}
