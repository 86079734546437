import React from 'react';
import './style.css'; // Custom styles for the footer
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer bg-dark text-white py-4">
            <div className="container">
                <div className="row">
                    {/* About Section */}
                    <div className="col-md-4 mb-4">
                        <h5 className="Footer-hea">About GSK Technology</h5>
                        <div className="mt-3">
                            <div className="mb-3">
                                <h6>VISION</h6>
                                <p className="text-light-gray small-font">We are helping your business grow with our latest software technologies. We believe that effective communication between software and technologies is the key to the future of all the business industry.</p>
                            </div>
                            <div>
                                <h6>MISSION</h6>
                                <p className="text-light-gray small-font">GSK TECH is a software development company that provides middleware products, systems integration services, and bespoke software solutions to deliver the best possible reliable software solutions to help our clients improve IT efficiency and business profitability.</p>
                            </div>
                        </div>
                    </div>

                    {/* Quick Links */}
                    <div className="col-md-4 mb-4">
                        <h5 className="Footer-hea">Our Products</h5>
                        <ul className="list-unstyled no-bullets">
                        
  <li><Link to="/pos" className="text-light-gray small-font">Beauty Industry POS</Link></li>
  <li><Link to="/inventory-management" className="text-light-gray small-font">Inventory Management</Link></li>
  <li><Link to="/employee-management-system" className="text-light-gray small-font">Employee Management System</Link></li>
  <li><Link to="/customer-relationship-managementcrm" className="text-light-gray small-font">Customer Relationship Management (CRM)</Link></li>
  <li><Link to="/retail-pos" className="text-light-gray small-font">Retail POS</Link></li>
  <li><Link to="/student-management-system" className="text-light-gray small-font">Student Management System</Link></li>
  <li><Link to="/small-and-medium-business-solutions" className="text-light-gray small-font">Small and Medium Business Solutions</Link></li>
  <li><Link to="/manufacturing-industry-erp" className="text-light-gray small-font">Manufacturing Industry ERP</Link></li>

                        </ul>
                    </div>

                    {/* Contact Info */}
                    <div className="col-md-4 mb-4">
                        <h5 className="Footer-hea">Contact Us</h5>
                        <ul className="list-unstyled contact-info">
                            <li><i className="fa fa-map-marker"></i> 5 Soon Lee Street, Pioneer Point, #02-55</li>
                            <li><i className="fa fa-phone"></i> Singapore 627607</li>
                            <li><i className="fa fa-phone"></i> Phone: (65) 91934818</li>
                            <li><i className="fa fa-fax"></i> Fax: (65) 64022265</li>
                            <li><i className="fa fa-envelope]"></i> Email: 
                        <a href="mailto:gsk@gsktechnology.com" className="text-white no-underline"> gsk@gsktechnology.com</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <hr className="border-secondary mt-0" />
                <div className="text-center">
                    <p className="mb-0">&copy; 2024 GSK Technology. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
