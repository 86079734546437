import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "./style.css";

export default function Header() {
    const navigate = useNavigate();
    const Logo = require('../../Images/Logo.png');
    return (

        <>
            <div className="logo d-flex flex-row gap-5">
                <img src={Logo} alt="comp logo" />
                <button onClick={() => navigate('/about-us')} type="button" className="submit-btn">
            Get Quotation
        </button>
            </div>

            <nav className="top-bar">
                <ul className="menu">
                    <li><Link to="/">Home</Link></li>
                    <li><a href="#about">Products</a>
                        <div className="dropdown-content">
                            <Link to="/pos">Beauty Industry POS</Link>
                            <Link to="/inventory-management">Inventory Management</Link>
                            <Link to="/employee-management-system">Employee Management System</Link>
                            <Link to="/customer-relationship-managementcrm">Customer Relationship Management (CRM)</Link>
                            <Link to="/retail-pos">Retail POS</Link>
                            <Link to="/student-management-system">Student Management System</Link>
                            <Link to="/manufacturing-industry-erp">Manufacturing Industry ERP</Link>
                            <Link to="/small-and-medium-business-solutions">Small and Medium Business Solutions</Link>



                        </div>
                    </li>
                    {/* <li><a href="#services">Download</a></li> */}
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/solutions">Solutions</Link></li>
                    <li><Link to="/our-clients">Clients</Link></li>
                    <li><Link to="/contact-us">Contact</Link></li>
                  
                    <li><Link to="/about-us">About Us</Link></li>
                </ul>
            </nav>
        </>
    )
}
