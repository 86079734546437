import React from 'react';
import './style.css'; // Make sure to include the CSS file
import Carousel from '../../Component/Carousel';
import Features from '../../Component/Features';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';


const App = () => {

    const Image1 = require('../../Images/Slide1.jpg');
    const Image2 = require('../../Images/Slide2.jpg');
    const Image3 = require('../../Images/Slide3.jpg');
    const Image4 = require('../../Images/Slide4.jpg');
    const carouselItems = [
        { src: Image1, alt: 'Slide 1' },
        { src: Image2, alt: 'Slide 2' },
        { src: Image3, alt: 'Slide 3' },
        { src: Image4, alt: 'Slide 4' },
    ];

    return (
        <>
            <Header />
            <Carousel items={carouselItems} />
            <Features />
            <div className="container text-center  demo-container">

                <h4 className="mb-2">GSK Technology: The Best POS Software Provider</h4>
                <p className="lead mb-4">
                    We are a team of cool developers determined to provide our users with the best POS software in the industry.
                </p>


                <div className="demo-button">
                    <p className="demo-btn-text">Request a Demo</p>
                </div>

            </div>
            <div className="mt-5">
                <Footer />
            </div>
        </>
    );
};

export default App;
