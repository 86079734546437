import React, { useState, useEffect } from 'react';
import './style.css'; // Import the CSS file

const Carousel = ({ items, interval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        // Auto-rotate the carousel
        const autoRotate = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, interval);

        // Clear interval on component unmount
        return () => clearInterval(autoRotate);
    }, [items.length, interval]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    };

    return (
        <div className="carousel">
            <button className="carousel-button prev" onClick={prevSlide}>
                &#10094; {/* Left arrow */}
            </button>
            <div className="carousel-slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}
                    >
                        <img src={item.src} alt={item.alt} />
                    </div>
                ))}
            </div>
            <button className="carousel-button next" onClick={nextSlide}>
                &#10095; {/* Right arrow */}
            </button>
        </div>
    );
};

export default Carousel;
