import React, { useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

const Business = () => {
  const [scrolled, setScrolled] = useState(false);
  const Business =require("../../Images/Business.png")

  // Scroll event handler to track user's scroll position
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setScrolled(true);  // Add the scrolled class after 100px scroll
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const bulletPoints = [
    'Multi User',
    'Multi Department and Multi Company',
    'Employee wise Sale',
    'Product wise Sale',
    'Group Sale',
    'Quotation and Invoicing',
    'Purchase Order',
    'Good Receiving',
    'Expenses',
    'Project wise cost plan',
    'Clients and Supplier info',
    'User wise Security Control',
    'GST & CPF Automation Report',
    'Staff Attendance',
    'Cheque Print',
    'Voucher Print',
    'Cash flow and 20 Reports'
  ];
  

  return (
    <>
    <Header/>
    <h2 className="pos-heading">Small and Medium Business solutions</h2>
    <div className={`product ${scrolled ? 'scrolled' : ''}`}>
      <div className="product-image-wrapper">
        <img src={Business} alt="Product" className="product-image" />
      </div>
      <div className="product-content">
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Business;
