// ContactUs.js
import React, { useState } from 'react';
import './style.css';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';

const ContactUs = () => {
    const [isCompany, setIsCompany] = useState(false);

    const handleContactTypeChange = (e) => {
        setIsCompany(e.target.value === 'company');
    };

    return (
        <>
            <Header />
            <div>
            <h2 className="pos-heading">Contact Us</h2>
              {/* <p>Feel free to contact us any time. We will get back to you as soon as we can!</p> */}
              </div>
            <div className="contact1-container">
                <div className="contact1-form">
                  


                    <form>
                        <input className="" type="text" placeholder="Name" required />
                        <input type="email" placeholder="Email" required />
                        <input type="tel" placeholder="Contact Number" required />
                        <input type="city" placeholder="City" required />

                        <div className='d-flex flex-row gap-5'>
                            <select onChange={handleContactTypeChange} required>
                                <option selected value="">Type</option>
                                <option value="individual">Individual</option>
                                <option value="company">Company</option>
                            </select>


                            {isCompany && (
                                <input type="text" placeholder="Company Name" required />
                            )}



                            <select required>
                                <option selected value="">Reason</option>
                                <option value="software">Software Inquiry</option>
                                <option value="product">Know About Product</option>
                                <option value="payment">Payment Issue</option>
                                <option value="support">Customer Support</option>
                                <option value="other">Other</option>
                            </select>
                        </div>


                        <textarea placeholder="Message" required></textarea>

                        <div className='contact1-form-btn '>Submit</div>
                    </form>
                </div>

                <div className="contact1-info">
                    <p>📧 gsk@gsktechnology.com</p>
                    <p>📞 (65) 91934818</p>
                    <p>📍 5 Soon Lee Street, Pioneer Point, #02-55
                        Singapore 627607</p>
                    <p>⏰ 08:00 - 18:00</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
